import React from 'react'
import { GiCookingPot } from 'react-icons/gi';
const Cooking = () => {
    return(
        <div>
          <GiCookingPot size = {50}/>  
          <div>Cooking</div>
        </div>
    )
}
export default Cooking