import React from 'react'
import { GiSelfLove } from 'react-icons/gi';

const SelfHelp = () => {
    return(
        <div>
            <GiSelfLove size = {50}/> 
            <div>Self-Help </div>
        </div>
    )
}
export default SelfHelp