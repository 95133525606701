import React from 'react'
import { GiNewBorn } from 'react-icons/gi';

const New = () => {
    return(
        <div>
             <GiNewBorn size = {50}/> 
             <div>New </div>
        </div>
    )
}
export default New