import React from 'react'
import {GiWhiteBook } from 'react-icons/gi';
const Fiction = () => {
    return(
        <div>
              <GiWhiteBook size = {50}/> 
              <div>Fiction</div>
        </div>
    )
}
export default Fiction