import React from 'react'
import { GiAncientRuins} from 'react-icons/gi';

const History = () => {
    return(
        <div>
               <GiAncientRuins size = {50}/> 
               <div>History</div>
        </div>
    )
}
export default History