import React from 'react'
import { BsQuestionCircle } from 'react-icons/bs';
const Mystery = () => {
    return(
        <div>
              <BsQuestionCircle size = {50}/> 
              <div>Mystery </div>
        </div>
    )
}
export default Mystery