import React from 'react'
import { SiAwesomelists } from 'react-icons/si';

const Best = () => {
    return(
        <div>
            <SiAwesomelists size = {50}/> 
            <div>Best </div>
        </div>
    )
}
export default Best