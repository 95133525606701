import React from 'react'
import { GiAncientSword } from "react-icons/gi";

const ScifiFan = () => {
    return(
        <div>
           <GiAncientSword size = {50}/> 
           <div>Scifi/Fantasy </div>
        </div>
    )
}
export default ScifiFan